body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Acumin Pro" "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: lightblue; */
  /* background-image: url('./bgTile-01.png'); */
  /* background-color: #555; */
  /* background-image: url("../img/nmf1H.png"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  -webkit-align-self: center;
          align-self: center;
}

.team {
  color: #666;
  font-style: italic;
  font-size: 1em;
}

.pgTitle {
  font-size: 3.5em;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
}


:root {
  --nmf-highlight-color: #6C5B91;
}

::-webkit-scrollbar:horizontal {
    -webkit-appearance: none;
    height: 0;
}

.desktopBanner {
  display: block;
}

.mobileBanner {
  display: none;
}

.banner {
  margin-bottom: 24px;
  width: 100vw;
}

.nmfTitle {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.weekOf {
  width: 100%;
  padding-top: 16px;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  color: white;
  background-color: #6C5B91;
  background-color: var(--nmf-highlight-color);
}

.weekOf p {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.06em;
}

.heroImg {
  width: 50vw;
  max-width: 500px;
  min-width: 200px;
}

.sotwTitle {
    font-size: 36px;
    font-weight: bold;
}

.sotwArtist {
  color: #6C5B91;
  color: var(--nmf-highlight-color);
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
}

.sotwL, .sotwR {
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  padding-left: 12px;
  margin: 32px;

  font-size: 18px;
  text-transform: uppercase;
  /* color: #F1570D; */
  /* color: #013F78; */
  color: #6C5B91;
  color: var(--nmf-highlight-color);
}

.sotwL {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.spacer {
  font-size: 48px;
}

.centered {
  margin-left: "auto";
  margin-right: auto;
}


.albumsBox {
  /* width: 617px; */
  height: 96px;
  padding-top: 20px;
  background-color: blue;
  color: white;
}

.albumsBox h1 {
  text-align: center;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.weekCol {
  padding-top: 232px;
  padding-left: 8px;
  width: 36px;
  height: 668px;
  margin-right: 20px;
  background-color: #ff2121
}

.albumBanner img {
  width: 64px;
  height: 64px;
}

.cycle {
  width: 320px;
  
  -webkit-scroll-snap-type: x mandatory;
  
      -ms-scroll-snap-type: x mandatory;
  
          scroll-snap-type: x mandatory;
  display: -webkit-flex;
  display: flex;
  -webkit-overflow-scrolling: touch;
}

/* .middleman {
  scroll-snap-type: x mandatory;
} */

.scrollers {
  scroll-snap-align: start;
}

.instructions {
  text-align: right;
  /* margin-right: 8px; */
  width: 100%;

  margin-bottom: 4px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  
  position: relative;
}


.slider {
    -webkit-scroll-snap-type: x mandatory;
        -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
    display: -webkit-flex;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    width: 85vw;
    min-width: 350px;
    max-width: 1100px;

    overflow: -moz-scrollbars-horizontal;
}

.albumCard {
    scroll-snap-align: center;
    text-align: center;
    position: relative;
}

.playlistComponent {
  width: 350px;
  height: 350px;
}

.playlistCol {
  padding-top: 128px;
  padding-left: 12px;
  height: 350px;
  margin-right: 16px;
  background-color: white;
  border: 2px solid;
}

.playlistCol2 {
  width: 350px;
  height: 40px;
  padding-top: 7px;
  background-color: white;
  border: 2px solid;
}

.toTop {

}

.toTopArrow {
  font-size: 32px;

}

.toTopArrow:hover {
  text-decoration: none;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;

}

@media (max-width: 450px) {
  .banner {
    margin-bottom: 8px;
  }

  .albumCard {
    scroll-snap-align: center;
  }

  .sotwL, .sotwR {
    margin: 8px;
    font-size: 16px;
  }

  .weekCol {
    width: 36px;
  }
}


.coverBox {
    width: 300px;
    height: 300px;
    border: 2px solid black;

}

.spotifyEmbed {
    border: 2px solid black;
    /* border-top: none */
}


.detailText {
    font-size: 14px;
    text-transform: uppercase;
}

.coverArt {
    width: 200px;
    height: 200px;
}

.elite {
  color: red;
  border-color: red;
}

.noMargin {
    padding: 0;
    margin: 0;
}

/********************************************/
/*  VERTICAL STYLING  */
/********************************************/
.vertPadder {
    height: 50px;
}

.topPad {
    padding-top: 11px;
    font-size: 18px;
    font-weight: bold;
}

.bottomPad {
    padding-top: 12px;
}

/********************************************/
/*  HORIZONTAL STYLING  */
/********************************************/
.horizPadder {
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    /* width: 10px; */
    /* padding-left: 4px; */
    /* text-orientation: sideways; */
    padding-left: 12px;
}

.leftPadder {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}
.narrowPanel {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: 800px;
}

@media (max-width: 850px) {
  .narrowPanel {
    width: 95%;
  }
}

.separator {
  /* margin-top: 70px; */
  border-width: 4px 0px;
  border-color: black;
  border-style: solid;
  height: 24px;
  margin-bottom: 32px;
}

.sendBox {
  height: 105px;
  width: 144px;
  /* background-color: salmon; */
}

.sendBoxContents {
  border-top: 2px solid black;
  color: black;
  text-transform: lowercase;
}

.sendBoxContents:hover {
  border-top: 2px solid gold;
  color: #ff8447;
}

.sendBoxContents h1 {
  font-size: 20px;
  font-weight: bold;
}

.sendBoxContents p {
  font-size: 12px;
  font-weight: normal;
  color: #555555;
}

#ikonz > a {
  margin-left: 10px;
  color: black;
  font-size: 22px;
}

@media (max-width: 700px) {
  .sendBox {
    height: 90px;
    width: 90px;
    /* background-color: salmon; */
  }

  #footer-text {
    font-size: 12px;
  }

  #ikonz > a {
    font-size: 16px;
  }
}

#resumeButton {
  background-color: #fff;
  border: 1px solid black;
  /* border-left: 1px solid black;
  border-right: 1px solid black */
  /* border-radius: 10px; */
  /* font-weight: bold; */
  /* text-transform: uppercase; */
}

h3 {
    font-size: 1.5em
}
.goBg {
    width: 100vw;
    background: url(/static/media/govstars2.d5778146.png);
    background-size: contain;
    background-position-x: center;
    background-position-y: top;
}

.doubleCenter {
    min-height: 100vh;
    width: 100%;
    padding-top: 15vh;
    padding-bottom: 25vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.goai {
    margin-bottom: 8px;
}

.fromGo {
    position: fixed;
    top: 20px;
    left: 40px;
}

.fromGo p {
    color: white;
    font-family: Garamond, serif;
    font-size: 24px;
    display: inline;
}

#toHome:hover {
    text-decoration: underline;
}

.mobileShow {
    display: none;
}

.streamingIcon {
    position: fixed;
    bottom: 32px;
    color: white;
}

.streamingIcon:hover {
    color: #c3baff;
}

.apple {
    right: 120px;
}

.spotify {
    right: 48px;
}

@media (max-width: 850px) {
    .mobileShow {
        display: block;
    }

    .mobileHide {
        display: none;
    }

    .streamingIcon {
        top: 24px;
    }

    .apple {
        right: 72px;
    }

    .spotify {
        right: 28px;
    }
}

.hunchContainer {
    height: 100vh;
    background-color: #434343;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.hunchBox {
    border: 2px solid #eeeeee;
    padding: 12px 40px;
    color: #eeeeee;
    font-size: 32px;
}

.hunchBox:hover {
    cursor: pointer;
    border: 2px solid #eeeeee;
    padding: 12px 40px;
    background-color: #eeeeee;
    transition: 0.6s;
    color: #434343;
    font-size: 32px;
}
.App {
  text-align: left;
  /* background: URL('tester/png'); */
  background-color: yellow;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  background-color: #fafafa;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: "SF Compact Display";
  font-weight: 900;
  /* color: white; */
  color: #0a0a0a;
  /* border: 4px solid black; */
  background-color: #aec4e8;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAR0lEQVRIiWP8////fwYIcGRgYDjAQAPARAtD0QEL1AcgcAFDlkqAERFatAN0Ca5RS0YtGbVk1JJRS3CAUUtGLRnKljAwMAAAtccJPhEEzfYAAAAASUVORK5CYII=);
  /* background: URL('tester.png') repeat; */
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

