.hunchContainer {
    height: 100vh;
    background-color: #434343;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hunchBox {
    border: 2px solid #eeeeee;
    padding: 12px 40px;
    color: #eeeeee;
    font-size: 32px;
}

.hunchBox:hover {
    cursor: pointer;
    border: 2px solid #eeeeee;
    padding: 12px 40px;
    background-color: #eeeeee;
    transition: 0.6s;
    color: #434343;
    font-size: 32px;
}