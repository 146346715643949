
:root {
  --nmf-highlight-color: #6C5B91;
}

::-webkit-scrollbar:horizontal {
    -webkit-appearance: none;
    height: 0;
}

.desktopBanner {
  display: block;
}

.mobileBanner {
  display: none;
}

.banner {
  margin-bottom: 24px;
  width: 100vw;
}

.nmfTitle {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.weekOf {
  width: 100%;
  padding-top: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  background-color: var(--nmf-highlight-color);
}

.weekOf p {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.06em;
}

.heroImg {
  width: 50vw;
  max-width: 500px;
  min-width: 200px;
}

.sotwTitle {
    font-size: 36px;
    font-weight: bold;
}

.sotwArtist {
  color: var(--nmf-highlight-color);
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
}

.sotwL, .sotwR {
  writing-mode: vertical-lr;
  padding-left: 12px;
  margin: 32px;

  font-size: 18px;
  text-transform: uppercase;
  /* color: #F1570D; */
  /* color: #013F78; */
  color: var(--nmf-highlight-color);
}

.sotwL {
  transform: rotate(-180deg);
}

.spacer {
  font-size: 48px;
}

.centered {
  margin-left: "auto";
  margin-right: auto;
}


.albumsBox {
  /* width: 617px; */
  height: 96px;
  padding-top: 20px;
  background-color: blue;
  color: white;
}

.albumsBox h1 {
  text-align: center;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.weekCol {
  padding-top: 232px;
  padding-left: 8px;
  width: 36px;
  height: 668px;
  margin-right: 20px;
  background-color: #ff2121
}

.albumBanner img {
  width: 64px;
  height: 64px;
}

.cycle {
  width: 320px;
  
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
}

/* .middleman {
  scroll-snap-type: x mandatory;
} */

.scrollers {
  scroll-snap-align: start;
}

.instructions {
  text-align: right;
  /* margin-right: 8px; */
  width: 100%;

  margin-bottom: 4px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  
  position: relative;
}


.slider {
    scroll-snap-type: x mandatory;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    width: 85vw;
    min-width: 350px;
    max-width: 1100px;

    overflow: -moz-scrollbars-horizontal;
}

.albumCard {
    scroll-snap-align: center;
    text-align: center;
    position: relative;
}

.playlistComponent {
  width: 350px;
  height: 350px;
}

.playlistCol {
  padding-top: 128px;
  padding-left: 12px;
  height: 350px;
  margin-right: 16px;
  background-color: white;
  border: 2px solid;
}

.playlistCol2 {
  width: 350px;
  height: 40px;
  padding-top: 7px;
  background-color: white;
  border: 2px solid;
}

.toTop {

}

.toTopArrow {
  font-size: 32px;

}

.toTopArrow:hover {
  text-decoration: none;
  text-decoration-color: white;

}

@media (max-width: 450px) {
  .banner {
    margin-bottom: 8px;
  }

  .albumCard {
    scroll-snap-align: center;
  }

  .sotwL, .sotwR {
    margin: 8px;
    font-size: 16px;
  }

  .weekCol {
    width: 36px;
  }
}