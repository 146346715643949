body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Acumin Pro" "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: lightblue; */
  /* background-image: url('./bgTile-01.png'); */
  /* background-color: #555; */
  /* background-image: url("../img/nmf1H.png"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  align-self: center;
}

.team {
  color: #666;
  font-style: italic;
  font-size: 1em;
}

.pgTitle {
  font-size: 3.5em;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
}
