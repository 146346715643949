.goBg {
    width: 100vw;
    background: url("../img/govstars2.png");
    background-size: contain;
    background-position-x: center;
    background-position-y: top;
}

.doubleCenter {
    min-height: 100vh;
    width: 100%;
    padding-top: 15vh;
    padding-bottom: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.goai {
    margin-bottom: 8px;
}

.fromGo {
    position: fixed;
    top: 20px;
    left: 40px;
}

.fromGo p {
    color: white;
    font-family: Garamond, serif;
    font-size: 24px;
    display: inline;
}

#toHome:hover {
    text-decoration: underline;
}

.mobileShow {
    display: none;
}

.streamingIcon {
    position: fixed;
    bottom: 32px;
    color: white;
}

.streamingIcon:hover {
    color: #c3baff;
}

.apple {
    right: 120px;
}

.spotify {
    right: 48px;
}

@media (max-width: 850px) {
    .mobileShow {
        display: block;
    }

    .mobileHide {
        display: none;
    }

    .streamingIcon {
        top: 24px;
    }

    .apple {
        right: 72px;
    }

    .spotify {
        right: 28px;
    }
}
