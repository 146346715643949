.narrowPanel {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: 800px;
}

@media (max-width: 850px) {
  .narrowPanel {
    width: 95%;
  }
}

.separator {
  /* margin-top: 70px; */
  border-width: 4px 0px;
  border-color: black;
  border-style: solid;
  height: 24px;
  margin-bottom: 32px;
}

.sendBox {
  height: 105px;
  width: 144px;
  /* background-color: salmon; */
}

.sendBoxContents {
  border-top: 2px solid black;
  color: black;
  text-transform: lowercase;
}

.sendBoxContents:hover {
  border-top: 2px solid gold;
  color: #ff8447;
}

.sendBoxContents h1 {
  font-size: 20px;
  font-weight: bold;
}

.sendBoxContents p {
  font-size: 12px;
  font-weight: normal;
  color: #555555;
}

#ikonz > a {
  margin-left: 10px;
  color: black;
  font-size: 22px;
}

@media (max-width: 700px) {
  .sendBox {
    height: 90px;
    width: 90px;
    /* background-color: salmon; */
  }

  #footer-text {
    font-size: 12px;
  }

  #ikonz > a {
    font-size: 16px;
  }
}

#resumeButton {
  background-color: #fff;
  border: 1px solid black;
  /* border-left: 1px solid black;
  border-right: 1px solid black */
  /* border-radius: 10px; */
  /* font-weight: bold; */
  /* text-transform: uppercase; */
}
