.App {
  text-align: left;
  /* background: URL('tester/png'); */
  background-color: yellow;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: "SF Compact Display";
  font-weight: 900;
  /* color: white; */
  color: #0a0a0a;
  /* border: 4px solid black; */
  background-color: #aec4e8;
  background-image: url("../img/bgTileW-01.png");
  /* background: URL('tester.png') repeat; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
