

.coverBox {
    width: 300px;
    height: 300px;
    border: 2px solid black;

}

.spotifyEmbed {
    border: 2px solid black;
    /* border-top: none */
}


.detailText {
    font-size: 14px;
    text-transform: uppercase;
}

.coverArt {
    width: 200px;
    height: 200px;
}

.elite {
  color: red;
  border-color: red;
}

.noMargin {
    padding: 0;
    margin: 0;
}

/********************************************/
/*  VERTICAL STYLING  */
/********************************************/
.vertPadder {
    height: 50px;
}

.topPad {
    padding-top: 11px;
    font-size: 18px;
    font-weight: bold;
}

.bottomPad {
    padding-top: 12px;
}

/********************************************/
/*  HORIZONTAL STYLING  */
/********************************************/
.horizPadder {
    writing-mode: vertical-lr;
    /* width: 10px; */
    /* padding-left: 4px; */
    /* text-orientation: sideways; */
    padding-left: 12px;
}

.leftPadder {
    transform: rotate(-180deg);
}